<template>
  <div>
    <form v-if="!personalizing" ref="shared-order-page-order-submission-form" method="post">
      <input type="hidden" name="_token" :value="csrf" />
      <div>
        <step-label>{{ $t("shared-order-approval.step", { step }) }}</step-label>
        <h1 class="mb-2 text-2xl font-extrabold text-black leading-tight font-title">
          {{ $t("shared-order-approval.step-2.customize_and_order") }}
        </h1>
        <p class="text-sm text-gray-400">
          {{ $t("shared-order-approval.step-2.customize_and_order_description") }}
        </p>
      </div>

      <div v-if="bannerToShow" class="mt-6">
        <component :is="bannerToShow" :deadline="orderDeadline" step="2" />
      </div>

      <div class="mt-10">
        <OrderInfo :order="order" :quotation="quotation" :unequal-totals="unequalTotals" />
      </div>

      <div class="mt-10">
        <div v-if="orderItems.length > 0" class="divide-y">
          <OrderItem
            v-for="item in orderItems"
            :key="item.id"
            :item="item"
            @personalize="startPersonalizing(item.id)"
          />
        </div>
        <div v-else class="text-sm text-gray-500 italic">
          {{ $t("shared-order-approval.step-2.no_order_items_found") }}
        </div>
      </div>

      <div class="mt-10">
        <OrderSummary
          :quotation="quotation"
          :delivery-address="data.requires_address_info ? order.address : null"
          :requires-address-info="data.requires_address_info"
          :countries="data.countries"
          :addresses="addresses"
        />
      </div>

      <div class="mt-10">
        <PrimaryButton
          v-tooltip="{
            content: canPlaceOrder
              ? ''
              : $t('shared-order-approval.step-2.please-add-address-first'),
          }"
          :disabled="!canPlaceOrder"
          :busy="isSubmittingForm"
          :aria-disabled="!canPlaceOrder"
          @click="isPlaceOrderConfirmationModalShown = true"
        >
          {{
            $t("shared-order-approval.step-2.place_order", {
              cost: currencyFormat(orderGrandTotal),
            })
          }}
        </PrimaryButton>

        <div v-if="hasErrors" class="mt-6">
          <p class="mb-2 font-bold text-red-300">
            {{ $t("shared-order-approval.step-2.order_has_errors") }}
          </p>
          <div v-for="(item, i) in itemsWithError" :key="`item-with-error-${i}`">
            <p class="mb-2">
              {{
                $t("shared-order-approval.step-2.review_item", {
                  item: `${item.optionName ? item.optionName + " " : ""} ${item.giftName}`,
                })
              }}.
            </p>
            <ul class="bg-red-50 text-red-300 border border-red-300 rounded px-4 py-2">
              <li v-for="error in item.errors" :key="error">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </form>

    <!-- Personalize -->
    <!-- Don't forget to update Step3 too when this is updated -->
    <PersonalizeOrderItem v-else :logo="data.logo" @cancel-personalizing="cancelPersonalizing" />

    <!-- Place Order Confirmation -->
    <ModalConfirmation
      :show="isPlaceOrderConfirmationModalShown"
      :title="$t('checkout.place_order')"
      @close="isPlaceOrderConfirmationModalShown = false"
      @confirm="submitForm"
    >
      <p
        v-html="
          $t('shared-order-approval.place_order_confirmation_description', {
            cost: currencyFormat(orderGrandTotal),
          })
        "
      ></p>
    </ModalConfirmation>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import { DateTime } from "luxon"
import { mapMutations, mapState } from "vuex"
import StepLabel from "../elements/text/StepLabel.vue"
import PrimaryButton from "../elements/buttons/PrimaryButton.vue"
import FadeIn from "../transitions/FadeIn.vue"
import PersonalizeOrderItem from "../shared-order-approval/PersonalizeOrderItem.vue"
import OrderInfo from "../shared-order-approval/elements/OrderInfo.vue"
import OrderSummary from "../shared-order-approval/elements/OrderSummary.vue"
import OrderItem from "./elements/OrderItem.vue"
import DeadlineSoonBanner from "@/components/shared-order-approval/elements/DeadlineSoonBanner.vue"
import DeadlineMissedBanner from "@/components/shared-order-approval/elements/DeadlineMissedBanner.vue"
import PersonalizesItems from "./mixins/PersonalizesItems"
import HasPrices from "../../mixins/has-prices"
import ResolvesSharedData from "../../mixins/resolves-shared-data"
import ModalConfirmation from "@/components/elements/modals/ModalConfirmation.vue"
import { currencyFormat } from "@/helpers/currency-helpers"
import { errorToast, successToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import Toggle from "../elements/toggles/Toggle.vue"
import ToggleCard from "@/components/elements/toggles/ToggleCard.vue"

export default {
  mixins: [PersonalizesItems, HasPrices, ResolvesSharedData],
  props: {
    errors: [Object, Array],
  },
  setup() {
    const { t } = useI18n()
    return { t, currencyFormat }
  },
  data() {
    return {
      isPlaceOrderConfirmationModalShown: false,
      isSubmittingForm: false,
    }
  },
  created() {
    // Set headers for Shared Order Page
    httpClient.defaults.headers.common["X-Original-Sender"] = window.location.href

    if (this.hasErrors)
      errorToast({ message: this.t("shared-order-approval.step-2.can_not_place_order_error") })

    this.setState({ attribute: "orderItems", value: this.data.orderItems })
    this.setState({ attribute: "order", value: this.data.order })
    this.setState({
      attribute: "companyEntity",
      value: this.data.companyEntity,
    })
    this.setState({
      attribute: "billingAddress",
      value: this.data.billingAddress,
    })
    this.$store.commit("product/setGroups", this.data.groups)
    this.setState({
      attribute: "signedQuotation",
      value: this.data.signedQuotation,
    })
    if (this.data.video_upload_url) {
      this.setState({
        attribute: "videoUploadUrl",
        value: this.data.video_upload_url,
      })
    }
  },

  computed: {
    ...mapState("sharedOrder", ["order", "orderItems"]),
    csrf() {
      return document.querySelector('meta[name="csrf-token"]').content
    },
    quotation() {
      return this.data.quotation
    },
    expectedDeliveryDate() {
      return DateTime.fromISO(this.order.expected_delivery_at)
    },
    deadlineDay() {
      return this.deadlineDays.place_order[
        this.order.has_physical_items ? "with_physical" : "digital_only"
      ]
    },
    orderDeadline() {
      return this.expectedDeliveryDate.minus({ days: this.deadlineDay.error })
    },
    bannerToShow() {
      const days = this.expectedDeliveryDate.diff(DateTime.local(), "days").toObject().days

      if (days < parseInt(this.deadlineDay.error)) {
        return "DeadlineMissedBanner"
      } else if (days < parseInt(this.deadlineDay.warning)) {
        return "DeadlineSoonBanner"
      }

      return false
    },
    canPlaceOrder() {
      return (
        (this.data.requires_address_info && this.order.address) || !this.data.requires_address_info
      )
    },
    addresses() {
      return this.data.addresses
    },
    hasErrors() {
      return this.itemsWithError.length > 0
    },
    itemsWithError() {
      return Object.keys(this.errors || {}).map(key => {
        const item = this.data.orderItems.find(item => item.id == key)

        return {
          giftName: item.gift.name[this.userLang],
          ...(item.option && { optionName: item.option.name[this.userLang] }),
          errors: this.errors[key]
            .map(errorObj => {
              return Object.values(errorObj).map(([error]) => error)
            })
            .flatten(1),
        }
      })
    },
  },

  watch: {
    unequalTotals(val) {
      if (val) {
        successToast({
          message: this.t("shared-order-approval.modified_order_message"),
        })
      }
    },
  },

  methods: {
    // TODO:: Below mutation "updateOrderItem" doesn't seem to exist!
    ...mapMutations("sharedOrder", ["setState", "updateOrderItem"]),
    // TODO:: Below store "employees" doesn't seem to exist!
    ...mapMutations("employees", ["loadFromPersonalizationData"]),
    submitForm() {
      this.isSubmittingForm = true
      this.$refs["shared-order-page-order-submission-form"].submit()
    },
  },

  components: {
    ToggleCard,
    DeadlineMissedBanner,
    DeadlineSoonBanner,
    FadeIn,
    Toggle,
    OrderInfo,
    OrderItem,
    OrderSummary,
    PersonalizeOrderItem,
    PrimaryButton,
    StepLabel,
    ModalConfirmation,
  },
}
</script>
