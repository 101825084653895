<template>
  <thead class="bg-gray-50">
    <tr class="relative">
      <th v-if="!readOnly" scope="col" class="sticky top-0 left-0 z-10"></th>
      <th
        v-if="$store.getters['product/showPersonalizationInputs']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.from')"
      ></th>
      <th
        v-if="$store.getters['product/showPersonalizationInputs']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.to')"
      ></th>
      <th
        v-if="$store.getters['product/emailAddressRequired']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.email')"
      ></th>
      <th
        v-if="!fixedPrice"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
      >
        <span class="pr-4" v-html="$t('forms.amount')"></span>
      </th>
      <th
        v-if="$store.getters['product/showPersonalizationInputs']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.language')"
      ></th>
      <th
        v-if="$store.getters['product/showPersonalizationInputs']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.message')"
      ></th>
      <th
        v-if="direct && (!homeDelivery || gift?.is_enabled_for_future_delivery)"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.delivery_date')"
      ></th>
      <th
        v-if="direct && !homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.delivery_time')"
      ></th>
      <th
        v-if="direct && $store.getters['product/isNonDigitalDirectHomeDelivery']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.recipient_first_name')"
      ></th>
      <th
        v-if="direct && $store.getters['product/isNonDigitalDirectHomeDelivery']"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.recipient_last_name')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.street')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.number')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.bus')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.zipcode')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.city')"
      ></th>
      <th
        v-if="homeDelivery"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase text-2xs whitespace-nowrap"
        v-html="$t('forms.country')"
      ></th>
      <th
        v-if="detailedReference"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase last-column text-2xs whitespace-nowrap"
        v-html="$t('forms.detailed_reference')"
      ></th>
      <th
        v-for="(field, index) in customerFields"
        :key="`customer-field-${index}`"
        scope="col"
        class="px-4 py-3 font-semibold tracking-wider text-left text-primary uppercase last-column text-2xs whitespace-nowrap"
      >
        {{ ucfirst(field) }}
      </th>
    </tr>
  </thead>
</template>

<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import { ucfirst } from "@/helpers/string-helpers"

defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
  customerFields: {
    type: Array,
    default: () => [],
  },
  direct: {
    type: Boolean,
    default: false,
  },
  homeDelivery: {
    type: Boolean,
    default: false,
  },
  detailedReference: {
    type: Boolean,
    default: false,
  },
  fixedPrice: {
    type: Boolean,
    default: false,
  },
  isVendorGift: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const gift = computed(() => store.state.product.gift.default ?? store.state.product.gift)
</script>
