<template>
  <tr
    class="relative transition group hover:bg-gray-50"
    :class="{
      error: validationError,
    }"
  >
    <td
      class="sticky left-0 inset-y-0 z-10 text-sm font-medium whitespace-nowrap transition group-hover:bg-gray-50 bg-white"
    >
      <div class="flex items-center py-4 pr-2 -mx-px space-x-2 transition border-r">
        <div class="flex items-center justify-between">
          <div class="ml-3 p-1">
            <CheckBox
              :model-value="selectedRecipients.includes(recipient.id)"
              @update:model-value="val => toggleEmployee({ checked: val, id: recipient.id })"
            />
          </div>

          <!-- v-if="canManageEmployees" -->
          <button
            type="button"
            class="p-1 text-gray-300 group-hover:text-gray-400 transition"
            @click="openEditorWithFocus(null)"
          >
            <EditIcon class="w-5 h-5 hover:text-primary" />
            <EditRecipient
              v-if="editingRecipient"
              :show="editingRecipient"
              :id="rowId"
              :field-to-focus="fieldToFocus"
              :show-address-fields="showAddressFields"
              @close="finishEditing"
            />
          </button>

          <!-- Remove recipient -->
          <button class="p-1 text-gray-300 group-hover:text-gray-400 transition" @click="remove">
            <TrashSolidIcon class="w-5 h-5 hover:text-primary" />
          </button>
          <!-- Show Preview -->
          <button
            v-if="hasExampleForPreview"
            class="p-1 text-gray-300 group-hover:text-gray-400 transition"
            @click="$emit('showPreview')"
          >
            <EyeOpenedIcon class="w-5 h-5 hover:text-primary" />
          </button>
        </div>
      </div>
      <!-- error border -->
      <div
        v-show="validationError"
        class="absolute top-0 bottom-0 left-0 z-20 w-0.5 bg-red-300"
      ></div>
      <LabelError
        v-show="validationError"
        class="absolute bottom-2 left-3 z-20"
        data-testid="error-indicator"
        icon-component="ExclamationCircleSolidIcon"
        :label="$t('errors.row_has_errors')"
      />
    </td>
    <td
      v-if="showPersonalizationInputs"
      class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap"
    >
      <input
        :id="`from-${index}`"
        type="text"
        :placeholder="$t('forms.from')"
        class="w-48"
        :value="recipient.from"
        @blur="$event => updateRecipientData($event.target.value, 'from')"
      />
    </td>
    <td
      v-if="showPersonalizationInputs"
      class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap"
    >
      <input
        :id="`to-${index}`"
        v-tooltip="{
          content: getErrorText('to'),
        }"
        type="text"
        :placeholder="$t('forms.to')"
        class="w-48"
        :class="{ error: hasError('to') }"
        :value="recipient.to"
        @blur="$event => updateRecipientData($event.target.value, 'to')"
      />
    </td>
    <td v-if="emailAddressRequired" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div class="relative">
        <input
          :id="`email-${index}`"
          v-tooltip="{
            content: getErrorText('email'),
          }"
          type="email"
          :placeholder="$t('forms.email')"
          class="w-48"
          :class="{ error: hasError('email') }"
          :value="recipient.email"
          :disabled="isEmployee && !canManageEmployees"
          @blur="$event => updateRecipientData($event.target.value, 'email')"
        />
        <span
          v-if="hasDefaultTrackingEmail"
          v-tooltip="{ content: $t('employees.no_email_for_track_and_trace') }"
          class="absolute inset-y-0 -left-5 flex items-center"
        >
          <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
        </span>
      </div>
    </td>
    <td v-if="!hasFixedPrice" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div
        v-tooltip="{
          content: hasFixedPrice ? $t('orders.fixed_price') : '',
        }"
        class="relative"
      >
        <div
          v-if="recipient.price || hasFixedPrice"
          class="absolute ml-1.5 inset-y-0 flex items-center mb-px"
        >
          <span>€</span>
        </div>
        <input
          :id="`price-${index}`"
          v-tooltip="{
            content: getErrorText('price'),
          }"
          type="number"
          min="0"
          :placeholder="$t('forms.amount')"
          class="w-24"
          :class="{ error: hasError('price') }"
          :style="{ paddingLeft: recipient.price || hasFixedPrice ? '1rem' : '0.5rem' }"
          :value="hasFixedPrice ? fixedPrice : recipient.price"
          :disabled="hasFixedPrice"
          @blur="$event => updateRecipientData($event.target.value, 'price')"
        />
      </div>
    </td>
    <td v-if="showPersonalizationInputs" class="p-4 text-sm text-gray-500">
      <SelectHtml
        :model-value="recipient.locale"
        :options="languages"
        :id="`locale-${index}`"
        v-tooltip="{
          content: getErrorText('locale'),
        }"
        name="locale"
        class="w-28"
        compact
        custom-bottom-margin
        @update:model-value="$event => updateRecipientData($event, 'locale')"
      >
        <template #option="{ option }">
          {{ $t(option.name) }}
        </template>
      </SelectHtml>
    </td>
    <OnClickOutside
      v-if="showPersonalizationInputs"
      @trigger="() => (messageTextAreaFocused = false)"
    >
      <td class="p-4 text-sm text-gray-500 whitespace-nowrap relative">
        <TextArea
          :model-value="recipient.message"
          :name="`message-${index}`"
          :placeholder="$t('forms.enter_message_here')"
          rows="2"
          class="w-52"
          :max-length="isKadonationGreetingCard || isSendKadonationGreetingCardToVendor ? 600 : 300"
          :assume-enter-as-many-characters="true"
          style="margin-bottom: 0"
          @update:model-value="updateRecipientData($event, 'message')"
          @focus="messageTextAreaFocused = true"
        />
        <p v-if="messageTextAreaFocused" class="absolute left-4 bottom-0">
          <ActionLink class="text-2xs" @click="openMessageEditor">
            {{ $t("forms.open_in_editor") }}
          </ActionLink>
        </p>
      </td>
    </OnClickOutside>
    <!-- <td v-if="!isVendorGift" class="p-1 text-sm text-gray-500 whitespace-nowrap">
      <video-upload
        ref="videoUpload"
        :value="recipient.thankyou_video"
        @input="data => updateRecipientData(data, 'thankyou_video')"
      />
    </td> -->
    <td
      v-if="
        directlyToRecipient &&
        (!$store.getters['product/isNonDigitalDirectHomeDelivery'] || futureDeliveryEnabled)
      "
      class="p-4 text-sm text-gray-500"
    >
      <DatePicker
        :id="`delivery_date-${index}`"
        :error="getErrorText('delivery_date')"
        type="date"
        class="w-36 h-10"
        placeholder="DD-MM-YYYY"
        :format="dateTimeFormat.date"
        :min-date="minDate"
        :model-value="recipient.delivery_date"
        :disabled-dates="holidays"
        :disabled-week-days="disabledWeekDays"
        @input="$event => updateRecipientData($event, 'delivery_date')"
      />
    </td>
    <td
      v-if="directlyToRecipient && !$store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      <DatePicker
        :id="`delivery_time-${index}`"
        :error="getErrorText('delivery_time')"
        type="time"
        class="w-24"
        placeholder="HH:MM"
        :format="dateTimeFormat.time"
        :model-value="recipient.delivery_time"
        :minute-step="15"
        @input="$event => updateRecipientData($event, 'delivery_time')"
      />
    </td>
    <td
      v-if="directlyToRecipient && $store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      <input
        :id="`recipient_first_name-${index}`"
        v-tooltip="{
          content: getErrorText('recipient_first_name'),
        }"
        name="recipient_first_name"
        type="text"
        :placeholder="$t('forms.enter_recipient_first_name')"
        class="w-64"
        :class="{ error: hasError('recipient_first_name') }"
        :value="recipient.recipient_first_name"
        @blur="$event => updateRecipientData($event.target.value, 'recipient_first_name')"
      />
    </td>
    <td
      v-if="directlyToRecipient && $store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      <input
        :id="`recipient_last_name-${index}`"
        v-tooltip="{
          content: getErrorText('recipient_last_name'),
        }"
        name="recipient_last_name"
        type="text"
        :placeholder="$t('forms.enter_recipient_last_name')"
        class="w-64"
        :class="{ error: hasError('recipient_last_name') }"
        :value="recipient.recipient_last_name"
        @blur="$event => updateRecipientData($event.target.value, 'recipient_last_name')"
      />
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div v-if="showAddressFields" class="relative">
        <input
          :id="`street-${index}`"
          v-tooltip="{
            content: getErrorText('street'),
          }"
          type="text"
          :placeholder="$t('forms.enter_street_here')"
          class="w-64"
          :class="{ error: hasError('street') }"
          :value="recipient.street"
          @blur="$event => updateRecipientData($event.target.value, 'street')"
        />
      </div>
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div v-if="showAddressFields" class="relative">
        <input
          :id="`street-number-${index}`"
          v-tooltip="{
            content: getErrorText('street_number'),
          }"
          type="text"
          :placeholder="$t('forms.enter_street_number_here')"
          class="w-48"
          :class="{ error: hasError('street_number') }"
          :value="recipient.street_number"
          @blur="$event => updateRecipientData($event.target.value, 'street_number')"
        />
      </div>
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <input
        v-if="showAddressFields"
        :id="`bus-${index}`"
        v-tooltip="{
          content: getErrorText('bus'),
        }"
        type="text"
        :placeholder="$t('forms.enter_bus_here')"
        :class="{ error: hasError('bus') }"
        :value="recipient.bus"
        @blur="$event => updateRecipientData($event.target.value, 'bus')"
      />
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div v-if="showAddressFields" class="relative">
        <input
          :id="`zipcode-${index}`"
          v-tooltip="{
            content: getErrorText('zipcode'),
          }"
          type="text"
          :placeholder="$t('forms.enter_zipcode_here')"
          class="w-48"
          :class="{ error: hasError('zipcode') }"
          :value="recipient.zipcode"
          @blur="$event => updateRecipientData($event.target.value, 'zipcode')"
        />
      </div>
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div v-if="showAddressFields" class="relative">
        <input
          :id="`city-${index}`"
          v-tooltip="{
            content: getErrorText('city'),
          }"
          type="text"
          :placeholder="$t('forms.enter_city_here')"
          class="w-48"
          :class="{ error: hasError('city') }"
          :value="recipient.city"
          @blur="$event => updateRecipientData($event.target.value, 'city')"
        />
      </div>
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="isHomeDelivery" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      <div v-if="showAddressFields" class="relative">
        <SelectHtml
          :id="`country-${index}`"
          v-tooltip="{
            content: getErrorText('country'),
          }"
          name="country"
          :model-value="recipient.country"
          :options="countries"
          class="w-64 truncate"
          compact
          custom-bottom-margin
          @update:model-value="handleCountryChange"
        >
          <template #option="{ option }">
            {{ $t(option.name) }}
          </template>
        </SelectHtml>
      </div>
      <span
        v-else
        v-tooltip="{ content: $t('employees.address_info_hidden') }"
        class="px-6 inline-block"
      >
        <exclamation-circle-icon class="h-5 w-5 text-yellow-700" />
      </span>
    </td>
    <td v-if="settings.detailed_invoice === true" class="p-4 text-sm text-gray-500 last-column">
      <input
        :id="`detailed_reference-${index}`"
        v-tooltip="{
          content: getErrorText('detailed_reference'),
        }"
        type="text"
        :placeholder="$t('forms.detailed_reference')"
        class="w-48"
        :class="{ error: hasError('detailed_reference') }"
        :value="recipient.detailed_reference"
        @blur="$event => updateRecipientData($event.target.value, 'detailed_reference')"
      />
    </td>
    <td
      v-for="(field, f_index) in customerFields"
      :key="`customer-field-${f_index}`"
      class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap"
    >
      <input
        :id="`from-${f_index}`"
        type="text"
        :placeholder="ucfirst(field)"
        class="px-2 rounded"
        :value="recipient.customer_fields[field]"
        @blur="$event => updateRecipientData($event.target.value, field, true)"
      />
    </td>
  </tr>
</template>

<script>
import { EditIcon, EyeOpenedIcon, TrashSolidIcon, ExclamationCircleIcon } from "@/components/icons"
import EditRecipient from "./EditRecipient.vue"
import { OnClickOutside } from "@vueuse/components"
import CheckBox from "@/components/elements/inputs/CheckBox.vue"
import DatePicker from "@/components/forms/DatePicker.vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { ucfirst } from "@/helpers/string-helpers"
import captureUserEvent from "@/helpers/captureUserEvent"
import { formatDateFromISO } from "@/helpers/date-helpers"
import TextArea from "@/components/elements/inputs/TextArea.vue"
import ActionLink from "@/components/elements/links/ActionLink.vue"
import LabelError from "../elements/labels/LabelError.vue"
import SelectHtml from "@/components/elements/inputs/SelectHtml.vue"

const DEFAULT_TRACKING_EMAIL = import.meta.env.VITE_DEFAULT_TRACK_AND_TRACE_EMAIL

export default {
  components: {
    CheckBox,
    DatePicker,
    EditRecipient,
    EditIcon,
    OnClickOutside,
    TrashSolidIcon,
    ExclamationCircleIcon,
    TextArea,
    EyeOpenedIcon,
    ActionLink,
    LabelError,
    SelectHtml,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    hasExampleForPreview: Boolean,
    validationError: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      required: true,
    },
    customerFields: {
      type: Array,
      default: () => [],
    },
    holidays: {
      type: Array,
      default: () => [],
    },
    canManageEmployees: Boolean,
  },
  emits: ["showPreview"],
  setup() {
    return { ucfirst }
  },
  data() {
    const rowId = Math.random().toString(36).substr(2, 5)

    return {
      editingRecipient: false,
      rowId,
      fieldToFocus: null,
      showingError: null,
      messageTextAreaFocused: false,
    }
  },
  computed: {
    ...mapState("recipients", ["selectedRecipients", "selectedTeams"]),
    ...mapState("sharedOrder", ["homeDelivery"]),
    ...mapGetters("sharedOrder", ["personalizingItem"]),
    ...mapState("product", ["locales", "directlyToRecipient", "settings"]),
    ...mapGetters("product", [
      "showPersonalizationInputs",
      "isKadonationGreetingCard",
      "isSendKadonationGreetingCardToVendor",
      "isKadonationVoucher",
      "hasFixedPrice",
      "fixedPrice",
      "isVendorGift",
      "emailAddressRequired",
    ]),
    gift() {
      return this.$store.state.product.gift?.default ?? this.$store.state.product.gift
    },
    languages() {
      return this.locales.length ? this.locales : this.$store.state.sharedOrder.locales
    },
    today() {
      return formatDateFromISO(new Date().toISOString, this.dateTimeFormat.date)
    },
    countries() {
      return this.$store.state.product.countries
    },
    isHomeDelivery() {
      return this.$store.getters["product/isNonDigitalDirectHomeDelivery"] || this.homeDelivery
    },
    hasMissingAddressInfo() {
      const { city, country, street, street_number, zipcode } = this.recipient
      return !(city && country && street && street_number && zipcode)
    },
    hasInvalidCountry() {
      return !this.countries.some(country => country.id === this.recipient.country)
    },
    showAddressFields() {
      const hasEmployeeId = Boolean(this.recipient.employee_id)
      return Boolean((this.canManageEmployees && hasEmployeeId) || !hasEmployeeId)
    },
    hasDefaultTrackingEmail() {
      return this.recipient.email === DEFAULT_TRACKING_EMAIL
    },
    isEmployee() {
      return this.recipient.employee_id !== null
    },
    futureDeliveryEnabled() {
      return this.gift?.is_enabled_for_future_delivery
    },
    deliveryDateInfo() {
      if (!this.recipient.country) return {}

      const info = this.$store.state.recipients.deliveryDateInfo.find(
        info => info.key === `${this.gift.id}-${this.recipient.country}`,
      )

      return info ? info.value : {}
    },
    minDate() {
      return this.deliveryDateInfo.earliestDeliveryDate || this.today
    },
    disabledWeekDays() {
      if (this.isKadonationVoucher || !this.futureDeliveryEnabled) return []
      const deliveryDays = this.deliveryDateInfo.deliveryDays

      if (!deliveryDays) return []

      const dayMap = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
      }

      const disabledDates = Object.entries(dayMap)
        .filter(entry => !deliveryDays.includes(entry[0]))
        .map(entry => entry[1])
      return [0, 6, ...disabledDates]
    },
  },
  created() {
    if (this.emailAddressRequired && this.recipient.employee_id && !this.recipient.email) {
      this.setRecipientData({
        id: this.recipient.id,
        attribute: "email",
        value: DEFAULT_TRACKING_EMAIL,
        meta: false,
      })
    }

    if (this.futureDeliveryEnabled && this.recipient.country) {
      this.getDeliveryDateInfoFromStore()
    }
  },
  methods: {
    ...mapMutations("recipients", ["toggleEmployee", "setRecipientData"]),
    ...mapActions("recipients", ["removeRecipient", "getDeliveryDateInfo"]),
    remove() {
      this.onConfirmRemove()
    },
    updateRecipientData(value, attribute, customerField = false) {
      if (attribute === "email" && this.isEmployee && !this.canManageEmployees) {
        return
      }

      this.setRecipientData({
        id: this.recipient.id,
        attribute,
        value,
        customerField,
      })
    },
    onConfirmRemove() {
      this.removeRecipient(this.recipient.id)
        .then((/*{ index }*/) => {
          this.$store.commit(
            "recipients/setSelectedTeams",
            this.selectedTeams.filter(team => this.recipient.group_id !== team),
          )

          if (!this.recipient.added_manually) {
            this.$store.commit("sharedOrder/addToRemovedMessages", this.recipient.id)
          }

          this.$emit("recipient-removed", this.index)
        })
        .catch(err => console.log(err))
    },
    startEditing() {
      this.$store.commit("recipients/edit", this.recipient.id)
      this.editingRecipient = true
    },
    finishEditing() {
      this.editingRecipient = false
    },
    openMessageEditor() {
      this.openEditorWithFocus(`message-${this.rowId}`)

      // track the activity in Mixpanel
      captureUserEvent("Ordering - Opening Larger Message Panel", {
        type: "single",
      })
    },
    openEditorWithFocus(fieldId) {
      this.fieldToFocus = fieldId
      this.startEditing()
    },
    showErrorInfo(key) {
      this.showingError = key
    },
    hideErrorInfo() {
      this.showingError = null
    },
    hasError(key) {
      return this.validationError && this.validationError[key]
    },
    getErrorText(key) {
      return this.validationError ? this.validationError[key] : ""
    },
    handleCountryChange(country) {
      this.updateRecipientData(country, "country")
      this.getDeliveryDateInfoFromStore()
    },
    getDeliveryDateInfoFromStore() {
      this.getDeliveryDateInfo({ giftId: this.gift.id, country: this.recipient.country })
    },
  },
}
</script>
<style scoped>
tr input,
tr select,
tr textarea {
  @apply px-2 m-0 text-sm placeholder-gray-300 transition bg-transparent group-hover:bg-white
  focus:bg-white border border-transparent group-hover:border-gray-300 focus:outline-none
  focus:border-blue-300 focus:ring-1 focus:ring-blue-300 rounded;
}

tr:hover input,
tr:hover select,
tr:hover textarea {
  @apply bg-white disabled:bg-transparent disabled:border-transparent disabled:cursor-not-allowed
  border-gray-300 focus:border-blue-300 focus:ring-1 focus:ring-blue-300;
}

tr input::placeholder,
tr select::placeholder,
tr textarea::placeholder {
  font-style: italic;
}

tr.error td {
  @apply pt-1 pb-10;
}

tr input.error,
tr select.error,
tr textarea.error,
tr:hover input.error,
tr:hover select.error,
tr:hover textarea.error {
  @apply border-red-300 hover:border-red-300 group-hover:border-red-300 focus:ring-0;
}
</style>
