<template>
  <div class="px-10 py-6 bg-gray-50 border border-gray-200 rounded-lg">
    <div class="flex justify-between">
      <div class="grid grid-cols-2 gap-x-4">
        <div>
          <p class="text-sm font-semibold">
            <span class="text-xs font-bold text-gray-400 uppercase">
              {{ $t("general.delivery_address") }}
            </span>
            <br />
            <template v-if="address">
              {{ address.recipient_first_name }} {{ address.recipient_last_name }}
              <br v-if="address.vat" />
              {{ address.vat }}
              <br v-if="address.street || address.number" />
              {{ address.street }} {{ address.number }}
              <br v-if="address.zip_code || address.city" />
              {{ address.zip_code }} {{ address.city }}
              <br v-if="deliveryAddressCountry" />
              {{ deliveryAddressCountry }}
            </template>
            <template v-else>-</template>
          </p>
        </div>

        <p class="text-sm font-semibold">
          <span class="text-xs font-bold text-gray-400 uppercase">
            {{ $t("shared-order-approval.step-2.invoiced_to") }}
          </span>
          <br v-if="invoicedTo.name" />
          {{ invoicedTo.name }}
          <br v-if="invoicedTo.vat" />
          {{ invoicedTo.vat }}
          <br v-if="invoicedTo.street || invoicedTo.number" />
          {{ invoicedTo.street }} {{ invoicedTo.number }}
          <br v-if="invoicedTo.zipcode || invoicedTo.city" />
          {{ invoicedTo.zipcode }} {{ invoicedTo.city }}
          <br v-if="invoicedTo.country" />
          {{ invoicedTo.country }}
        </p>
      </div>

      <div class="text-right">
        <p
          v-if="quotation && unequalTotals"
          class="flex items-center justify-between text-gray-400"
        >
          <span class="flex-1 text-sm whitespace-nowrap">
            {{ $t("quotation.quotation") }} {{ $t("general.total") }}
          </span>
          <span class="w-32 font-bold font-title whitespace-nowrap line-through">
            {{ currencyEuro(quotationTotal - homeDeliveryFee - shippingFee - handlingFee) }}
          </span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">
            {{ $t("general.total") }}
          </span>
          <span class="w-32 font-bold font-title whitespace-nowrap">
            {{ currencyEuro(total - homeDeliveryFee - shippingFee - handlingFee) }}
          </span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">{{ $t("general.handling_fee") }}</span>
          <span class="w-32 font-bold font-title whitespace-nowrap">
            {{ currencyEuro(handlingFee) }}
          </span>
        </p>
        <p v-if="homeDeliveryFee" class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">{{
            $t("general.home_delivery_fee")
          }}</span>
          <span class="w-32 font-bold font-title whitespace-nowrap">
            {{ currencyEuro(homeDeliveryFee) }}
          </span>
        </p>
        <p v-if="shippingFee" class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">{{ $t("general.shipping_fee") }}</span>
          <span class="w-32 font-bold font-title whitespace-nowrap">
            {{ currencyEuro(shippingFee) }}
          </span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">{{ $t("general.vat") }}</span>
          <span class="w-32 font-bold font-title whitespace-nowrap">
            {{ currencyEuro(vat) }}
          </span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex-1 text-sm whitespace-nowrap">{{ $t("general.grand_total") }}</span>
          <span class="w-32 font-bold text-pink-300 font-title whitespace-nowrap">
            {{ currencyEuro(orderGrandTotal) }}
          </span>
        </p>
      </div>
    </div>

    <div>
      <div class="mt-6 w-64 grid grid-cols-2 gap-x-4">
        <div>
          <p class="text-xs font-bold text-gray-400 uppercase">
            {{ $t("general.po_number") }}
          </p>
          <p class="text-sm">{{ order.po_number || "-" }}</p>
        </div>
        <div>
          <p class="text-xs font-bold text-gray-400 uppercase">
            {{ $t("general.cost_center") }}
          </p>
          <p class="text-sm">{{ order.cost_center || "-" }}</p>
        </div>
      </div>
      <p>
        <ActionLink class="mt-4 text-xs" @click="poModalOpen = !poModalOpen">
          {{ $t("shared-order-approval.step-2.edit_po_cost_center") }}
        </ActionLink>
      </p>
      <ModalDefault
        :show="poModalOpen"
        :title="$t('shared-order-approval.step-2.edit_po_title')"
        @close="poModalOpen = false"
        hide-icon
      >
        <form :action="zRoute('order-quotation.update-po-number', order)" method="post">
          <input type="hidden" name="_token" :value="csrf" />
          <input type="hidden" name="_original_sender" :value="originalSender" />

          <TextInput
            name="po_number"
            :label="$t('forms.po_number')"
            :model-value="order.po_number"
          />

          <TextInput
            name="cost_center"
            :label="$t('forms.cost_center')"
            :model-value="order.cost_center"
          />

          <div class="flex gap-3 mt-10">
            <TertiaryButton compact @click="poModalOpen = false">{{
              $t("forms.cancel")
            }}</TertiaryButton>
            <PrimaryButton type="submit" compact>{{ $t("forms.save") }}</PrimaryButton>
          </div>
        </form>
      </ModalDefault>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import HasPrices from "../../../mixins/has-prices"
import ModalDefault from "@/components/elements/modals/ModalDefault.vue"
import { currencyEuro } from "@/helpers/currency-helpers"
import TextInput from "@/components/elements/inputs/TextInput.vue"
import ActionLink from "@/components/elements/links/ActionLink.vue"
import PrimaryButton from "../../elements/buttons/PrimaryButton.vue"
import TertiaryButton from "../../elements/buttons/TertiaryButton.vue"

export default {
  mixins: [HasPrices],
  props: {
    quotation: Object,
    requiresAddressInfo: Boolean,
    countries: { type: Array, default: () => [] },
    addresses: { type: Array, default: () => [] },
  },
  setup() {
    return { currencyEuro }
  },
  data() {
    return {
      poModalOpen: false,
    }
  },

  computed: {
    ...mapState("sharedOrder", ["order"]),
    ...mapGetters("sharedOrder", ["invoicedTo", "address"]),
    csrf() {
      return document.querySelector('meta[name="csrf-token"]')?.content
    },
    originalSender() {
      return window.location.href
    },
    deliveryAddressCountry() {
      if (!this.address?.country) {
        return null
      }

      const country = this.countries.find(({ value }) => value === this.address.country)

      return country ? country.country : this.address.country
    },
  },

  components: {
    ModalDefault,
    PrimaryButton,
    TertiaryButton,
    TextInput,
    ActionLink,
  },
}
</script>
